/* You can add global styles to this file, and also import other style files */

html, body{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  background-color: var(--color-background);
  min-height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, a, p, div, span, strong {
  word-break: keep-all;
}

hr{
  margin: 0;
}

pre{
  overflow: auto;
}

button.p-button:not(.p-button-icon-only){
  width: 100%
}

.bodyContent{
  margin-top: 30vh;
  width: 100%;
  margin-bottom: 30vh;
}

.rightImageBox > div{
  margin-right: 15px;
}

.leftImageBox > div{
  margin-left: 15px;
}

.rightImageBox, .leftImageBox{
  margin-bottom: 100px;
}

.middled{
  position: relative;
}

.middled > div{
  position: absolute;
  z-index: 5;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
}

.middled > div > h2{
  color: white;
}

.croppedImageWidth{
  width: 70%;
}

::ng-deep .p-panel.p-panel-toggleable.p-panel-expanded > .p-panel-header{
  transition: border-radius .5s cubic-bezier(0.83, 0.02, 1, 1);
}

:root{
  --plyr-color-main: var(--color-blue-accent);
  --plyr-menu-background: var(--color-background-accent);
  --plyr-menu-color: var(--color-text);
}

.p-slider {
  background: #c8c8c8;
  border: 0 none;
  border-radius: 3px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: white;
  border: 1px solid var(--color-accent-text);
  border-radius: 100%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #8dcdff;
}
.p-slider .p-slider-range {
  background: var(--color-blue-accent);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #666666;
  border-color: #007ad9;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

:root .blogContent img {
  max-width: 100%;
  height: fit-content;
}

:root .blogContent a{
  word-break: break-all;
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.gradient-track{
  overflow: hidden;
  position: relative;
}

.gradient-track:before {
  --size: 0;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
  background: radial-gradient(circle closest-side, rgba(var(--gradient)), transparent);
}

.gradient-track:hover:before {
  --size: var(--radial-size, 200px);
}

@media only screen and (max-width: 992px) {
  .croppedImageWidth{
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .middled{
    flex-direction: column;
  }
  .middled > div{
    position: unset;
    color: var(--color-text) !important;
  }
  .middled > div > h2{
    color: var(--color-text) !important;
  }
}

@media only screen and (max-width: 768px) {
  p-confirmdialog > .p-dialog-mask > .p-dialog {
    bottom: 50px
  }
}
